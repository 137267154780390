import React, { Component } from 'react';
import './SpinnerLoad.css'

export class SpinnerLoad extends Component {
    render() {
        return (
            <div class="loader">Loading...</div>
        )
    }
}

export default SpinnerLoad
