

import React, { Component } from 'react'
import NavBar from "./Component/NavBar/NavBar";
 
// import { BrowserRouter, Route, Routes } from "react-router-dom";
import DepartmentsStudents from "./Container/DepartmentsStudents/DepartmentsStudents";
import HomePage from "./Container/HomePage/HomePage";
import StudentPage from "./Container/StudentPage/StudentPage";
import AddStudentForm from "./Container/AddStudentForm/AddStudentForm";
import LoginPage from "./Container/LoginPage/LoginPage";
import RegisterPage from "./Container/RegisterPage/RegisterPage";
import StudentProfile from "./Container/StudentProfile/StudentProfile";
import StudentProfileEditable from "./Container/StudentProfileEditable/StudentProfileEditable";
 import PrivateRoute from "./Component/PrivateRoute";

 
  import {isLogin} from './Component/utils/index';
// import PrivateRoute from "./Component/PrivateRoute";
import AuthContext from  './Component/Context/AuthContext';
import PublicRoute from "./Component/PublicRoute";

import {
  BrowserRouter as Router,
  Switch,
  Route,
  Link
} from 'react-router-dom';
import axios from 'axios';

// import LoginPage from "./Container/LoginPage/LoginPage";
// import RegisterPage from "./Container/RegisterPage/RegisterPage";


 
 
class App extends Component {

  constructor(props) {
    super(props);
  }

 
   state = {
       user:{},
       loggedIn:"",
       isAuthenticated:false,
       
   }
  //  login = () => {
  //   this.setState({ isAuthenticated: true });
  // }

  // logout = () => {
  //   this.setState({ isAuthenticated: false });
  // }

   componentDidMount(){
    //    Login user crediential
    axios.get('/userprofiledetails' )
    .then( (response) => {
     this.setUser(response.data);
  
    })
    .catch(  (error) => {
      console.log(error);
    });
  var TOKEN_KEY_VALUE=  localStorage.getItem("TOKEN_KEY")
    if(TOKEN_KEY_VALUE!=null && TOKEN_KEY_VALUE!=undefined && TOKEN_KEY_VALUE !=""){
      this.setState({
        loggedIn:true
      })
      }
   }

   setUser = (user)=>{
      this.setState({user: user}) 
   }
  
   setLoggedIn=()=>{
     this.setState({
       loggedIn:true
     })
   }

   setLoggedOut = () =>{
    this.setState({
      loggedIn:false
    })
    localStorage.removeItem("TOKEN_KEY");
   }
    render() {
      const loggedIn = this.state.loggedIn;
      const setLoggedIn = this.setLoggedIn;
     
      console.log(loggedIn);
        return (
            <>
     <Router> 
     <AuthContext.Provider value={{ loggedIn, setLoggedIn }}>
          <NavBar islogin={loggedIn} islogOut={this.setLoggedOut} user={this.state.user} setUser={this.setUser} />
          </AuthContext.Provider>
          <Switch>
          
             <Switch>
             {/* <PublicRoute restricted={false} component={StudentPage} path="/" exact /> */}
             <PublicRoute restricted={false} component={StudentPage} path="/students" exact />
             <PublicRoute restricted={false} component={AddStudentForm} path="/student/add" exact />

             <PublicRoute restricted={false} component={DepartmentsStudents} path="/department/:id" exact />


          <PublicRoute restricted={false} component={HomePage} path="/" exact />
          <PublicRoute restricted={isLogin()} component={()=> <LoginPage setLogin = {this.setLoggedIn} user={this.state.user} />} path="/login" exact />
          <PublicRoute restricted={isLogin()}  component={RegisterPage} path="/register" exact />
         
          <PrivateRoute component={StudentProfileEditable} path="/profile" exact />
        
          <Route component={HomePage} />
        </Switch>
          
        </Switch>
   
    </Router>
            </>
        )
    }
}

export default App