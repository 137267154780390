import React, { Component } from 'react';
import axios from 'axios';
import meclogo from './../../Component/NavBar/headerlogo.png';

export class AddStudentForm extends Component {
  state = {
      
       studentdata:{
        name:"",
        phone_no:"", 
        department_id :"1",
        merit_position:"",
        email:"",
        hometown:"",
        school:"",
        college:"",
        bloodgroup:"O+",
        height:"",
        emergency_contact:"",
        batch_no:"12",
        dateofbirth:""
       },
      
       studentname:"",
       studentemail:"",
       heightdata : ["4'1\"","4'2\"","4'3\"","4'4\"","4'5\"","4'6\"","4'7\"","4'8\"","4'9\"","4'10\"","4'11\"","5'","5'1\"","5'2\"","5'3\"","5'4\"","5'5\"","5'6\"","5'7\"","5'8\"","5'9\"","5'10\"","5'11\"","6'","6'1\"","6'2\"","6'3\"","6'4\"","6'5\"","6'6\"","6'7\"","6'8\"","6'9\"","6'10\"","6'11\""],
       submit:false,

  }

  toastHideController = () =>{

this.setState({submit:false})
  }


  inputBoxChangeHandler = (e)=>{
    var oldData = this.state.studentdata;
    var newData = {
      ...oldData,
      [e.target.id]:e.target.value
    }
    this.setState({
      studentdata:newData,
      submit:false,
   
    })

  }

  _handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      //  this.formSubmitHandler.bind()
      this.formSubmitHandler()
    }
  }

  formSubmitHandler = () =>{

if(this.state.submit==false && this.state.studentdata.name != ""  && this.state.studentdata.phone_no != ""){
  this.setState({
    studentname:this.state.studentdata.name,
    studentemail:this.state.studentdata.email
    })
 
   axios.post('/student/add', this.state.studentdata )
    .then(  (response) =>{

      axios.post('/mail/welcomemail', {'name':this.state.studentname, 'email':this.state.studentemail})
      .then(  (response) =>{
        console.log(response);
      })
      .catch(  (error)=> {
        console.log(error);
      });


      console.log(response);
      this.setState({
        studentdata:{
          name:"",
          phone_no:"", 
          department_id :"1",
          merit_position:"",
          email:"",
          hometown:"",
          school:"",
          college:"",
          bloodgroup:"O+",
          height:"",
          emergency_contact:"",
          batch_no:"12",
          dateofbirth:""
        },
       
          })
      this.setState({
        submit:true
          })
   
    })
    .catch(  (error)=> {
      console.log(error);
    });

}
 

  }
    render() {

  
 var heightRender  = this.state.heightdata.map(i=>{
   return   <option value={i}>{i}</option>
 }) ;
//  var renderit = heightRender.map(key => {
//   //  return   <option value={heightRender}>O+</option>
 
//  })


        return (
            <div class="container pb-5"style={{"padding-top": "10px"}}>
    
   
            <form>
              <h2 class="d-flex justify-content-center pt-2 pb-2">Students Information Form</h2>
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="first">Name</label>
                    <input onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.name}     type="text" class="form-control" placeholder="Name" id="name"  />
                  </div>
                </div>
              
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="phone">Phone Number</label>
                    <input  onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.phone_no}   type="tel" class="form-control" id="phone_no" placeholder="phone"  />
                  </div>
                </div>
               
              
              </div>
        
        
              <div class="row">
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="company">Department</label>
                    <select  onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.department_id}  class="form-select" aria-label="Default select example" id="department_id"
                    >
                      <option value="2">Department of Civil Engineering</option>
                      <option value="1">Department of Electrical and Electronics Engineering (EEE)</option>
                      <option value="3">Department of Computer Science Engineering (CSE)</option>
                    </select>
                  </div>
        
        
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="Number">Position</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.merit_position}   type="number" class="form-control" id="merit_position" placeholder="position"/>
                  </div>
        
                </div>
              
              </div>
            
        
        
              <div class="row">
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="email">Email address</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.email}   type="email" class="form-control" id="email" placeholder="email"/>
                  </div>
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="hometown">Hometown</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.hometown}   type="text" class="form-control" id="hometown" placeholder="Hometown"/>
                  </div>
        
                </div>
              
              </div>
        
              <div class="row">
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="school">School</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.school}   type="text" class="form-control" id="school" placeholder="School"/>
                  </div>
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="college">College</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.college}   type="text" class="form-control" id="college" placeholder="College"/>
                  </div>
        
                </div>
              
              </div>
              <div class="row">
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="bloodgroup">Blood Group</label>
                    <select onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.bloodgroup}   class="form-select"  id="bloodgroup" aria-label="Default select example">
                      <option value="O+">O+</option>
                      <option value="O-">O-</option>
                      <option value="A+">A+</option>
                      <option value="A-">A-</option>
                      <option value="B+">B+</option>
                      <option value="B-">B-</option>
                      <option value="AB+">AB+</option>
                      <option value="AB-">AB-</option>
        
                    </select>
                  </div>
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="height">Height</label>
                    {/* <input  onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.height}  type="text" class="form-control" id="height" placeholder="Height"/> */}




                    <select onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.height}   class="form-select"  id="height" aria-label="Default select example">
           {heightRender}
                     
          </select>


                  </div>
        
                </div>
              
              </div>
              <div class="row">
                <div class="col-md-6">
        
                  <div class="form-group">
                    <label for="School">Emargency Contact</label>
                    <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.emergency_contact}   type="text" class="form-control" id="emergency_contact" placeholder="Emargency Contact"/>
                  </div>
                </div>
              
        
                <div class="col-md-6">
                  <div class="form-group">
                    <label for="batch_no">Batch</label>
                    {/* <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.batch_no}   type="text" class="form-control" id="batch_no" placeholder="Batch"/> */}

                    <select onChange={this.inputBoxChangeHandler}   value={this.state.studentdata.batch_no}   class="form-select"  id="batch_no" aria-label="Default select example">
                      <option value="1">1'th</option>
                      <option value="2">2'th</option>
                      <option value="3">3'th</option>
                      <option value="4">4'th</option>
                      <option value="5">5'th</option>
                      <option value="6">6'th</option>
                      <option value="7">7'th</option>
                      <option value="8">8'th</option>
                      <option value="9">9'th</option>
                      <option value="10">10'th</option>
                      <option value="11">11'th</option>
                      <option value="12">12'th</option>
                      <option value="13">13'th</option>
                      <option value="14">14'th</option>
                      <option value="15">15'th</option>
                     
        
                    </select>






                  </div>
        
                </div>
              
              </div>


              <div class="col-md-6">
        
        <div class="form-group">
          <label for="School">Date Of Birth</label>
          <input onChange={this.inputBoxChangeHandler}  value={this.state.studentdata.dateofbirth}   type="date" class="form-control" id="dateofbirth" placeholder="Emargency Contact"/>
        </div>
      </div>
        
        
        
        
              {this.state.submit?<div class="position-fixed bottom-0 end-0 p-3" style={{"z-index": "11"}}>
  <div id="liveToast" class="toast  show" role="alert" aria-live="assertive" aria-atomic="true">
    <div class="toast-header">
      <img src={meclogo} style={{'width':'80px'}} class="rounded me-2" alt="..."/>
      <strong class="me-auto"> </strong>  
       <button onClick={this.toastHideController} type="button" class="btn-close" data-bs-dismiss="toast" aria-label="Close"></button>
    </div>
    <div class="toast-body">
    {this.state.studentname}'s Data Has Submitted Successfully!
    </div>
  </div>
</div>:"" }

               







              <div class="d-flex justify-content-center pt-5">
             
              {/* onKeyDown={this._handleKeyDown} */}
             <button class="btn btn-primary"   onClick={this.formSubmitHandler} type="button"  >
                Submit
                </button> 
        
              
              </div>
        
            </form>
          </div>
        )
    }
}

export default AddStudentForm
