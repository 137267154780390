import React, { Component } from 'react'
import { login } from '../../Component/utils/index';
import axios from 'axios';
import { Redirect } from 'react-router-dom';
export class LoginPage extends Component {
 
  state = {

    email:"",
    password:"",
    message:"", 
    loggedIn:false,
    incorrect:false

  }

  formSubmitHandler=(e)=>{
  
e.preventDefault();  
// this.props.history.push('/profile');

 
const data = {
  email:this.state.email,
  password:this.state.password
}

console.log(data);
axios.post('/login', data )
.then( (response) => {
  console.log(response);
  const TOKEN_KEY_VALUE = response.data.token;
  if(TOKEN_KEY_VALUE!=null && TOKEN_KEY_VALUE!=undefined && TOKEN_KEY_VALUE !=""){
    login(TOKEN_KEY_VALUE);
    this.setState({
      loggedIn:true,
      incorrect:false
    });
    this.props.setLogin();
  }else{
this.setState({incorrect:true})
  }
  this.props.history.push('/profile');
  <Redirect to="/profile" />
//  this.props.history.push('/profile');
// const { history } = this.props;
// history.push("/profile")
//  localStorage.setItem('TOKEN_KEY' , response.data.token );

})
.catch(  (error) => {
  console.log(error);
});
  }





    render() {

    
        return (
    
            <div class="container py-5 h-100"> {
              this.state.loggedIn?    <Redirect to="/profile"/>:""        } 
              <div class="row d-flex justify-content-center align-items-center h-100">
                <div class="col-12 col-md-8 col-lg-6 col-xl-5">
                  <div class="card shadow-2-strong" style={{"border-radius": "1rem"}}>
                    <div class="card-body p-5 text-center">
          
                      <h3 class="mb-5">Sign in</h3>
          
                    

                      <div class="form-floating mb-3">
  <input type="email"   onChange={(e)=>{this.setState({email:e.target.value})}} class="form-control" id="email" placeholder="name@example.com"/>
  <label for="email">Email address</label>
</div>

<div class="form-floating mb-3">
  <input type="password"   onChange={(e)=>{this.setState({password:e.target.value})}} class="form-control" id="password" placeholder="Password"/>
  <label for="password">Password</label>
</div>


{this.state.incorrect?<div class="alert alert-danger d-flex align-items-center" role="alert">
  
  <div>
   Email or Password Is Incorrect
</div>
 
</div>:""}
              
          
                      <button class="btn btn-primary btn-lg btn-block" onClick={this.formSubmitHandler} type="submit">Login</button>
          
                    
          
                    </div>
                  </div>
                </div>
              </div>
            </div>
    
        )
    }
}

export default LoginPage
